import { atom } from 'jotai';

export interface ITopNavProps {
  isRelative?: boolean;
  isTransparent?: boolean;
  black?: boolean;
}
export const topNavigationState = atom<
  ITopNavProps & {
    pathname?: string;
  }
>({});
