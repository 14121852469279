'use client';

import { clsx } from 'clsx';

import { IconAppleInc, IconGPlay, TextLarge, TextSmall } from '@squadnet/frontend-web-ui';

import { configs } from '@/config/shared';
import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import useAuthentication from '@/hooks/guards/useAuthenticationGuard';

const { appstoreUrl, playstoreUrl } = configs;

interface DownloadsProps {
  appstore?: string;
  radiant?: boolean;
}
export const Downloads = ({ appstore, radiant }: DownloadsProps) => {
  const analytics = useAnalytics();
  const { isAuthenticated } = useAuthentication();

  return (
    <div className='flex flex-wrap items-center'>
      <a
        onClick={() => {
          analytics.track('AppDownloadClicked', {
            platform: 'Ios',
            pathname: window.location.pathname,
            isAuthenticated,
          });
          window.open(appstoreUrl, '_blank');
        }}
        href={appstore || appstoreUrl}
        target='_blank'
        rel='noreferrer'
        className={clsx(
          'mb-3 mr-3 flex items-center justify-center rounded-md p-2',
          radiant
            ? 'bg-gradient-to-r from-secondary to-primary'
            : 'border-[1px] border-solid border-mono-xlight bg-[#000]',
        )}
      >
        <IconAppleInc width={28} variant='white' className='mr-2' />
        <div>
          <TextSmall variant='inverted' size='xs'>
            Download on the
          </TextSmall>
          <TextLarge variant='inverted' className='font-bold'>
            App Store
          </TextLarge>
        </div>
      </a>
      <a
        onClick={() => {
          analytics.track('AppDownloadClicked', {
            platform: 'Android',
            pathname: window.location.pathname,
            isAuthenticated,
          });
          window.open(playstoreUrl, '_blank');
        }}
        href={playstoreUrl}
        target='_blank'
        rel='noreferrer'
        className={clsx(
          'mb-3 flex items-center justify-center rounded-md p-2',
          radiant
            ? 'bg-gradient-to-r from-secondary to-primary'
            : 'border-[1px] border-solid border-mono-xlight bg-[#000]',
        )}
      >
        <IconGPlay width={32} variant='white' className='mr-2' />
        <div>
          <TextSmall variant='inverted' size='xs'>
            GET IT ON
          </TextSmall>
          <TextLarge variant='inverted' className='font-bold'>
            Google Play
          </TextLarge>
        </div>
      </a>
    </div>
  );
};
