import { useAtomValue, useSetAtom } from 'jotai';
import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { ITopNavProps, topNavigationState } from '@/state/topNavigation';

export const useGetTopNavigation = (opts: ITopNavProps) => {
  const pathname = usePathname();
  const settings = useAtomValue(topNavigationState);
  return useMemo(() => {
    if (pathname !== settings?.pathname) return opts;
    return { ...opts, ...(settings || {}) };
  }, [pathname, JSON.stringify(settings)]);
};

export const useSetTopNavigation = () => {
  const setter = useSetAtom(topNavigationState);
  return {
    setter,
  };
};
