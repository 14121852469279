'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

import { useSetTopNavigation } from '@/hooks/useTopNavigation';

export interface ITopNavigationSyncProps {
  isRelative?: boolean | null;
  isTransparent?: boolean | null;
  black?: boolean | null;
}
export const TopNavigationSync = (props: ITopNavigationSyncProps) => {
  const pathname = usePathname();
  const { setter } = useSetTopNavigation();
  useEffect(() => {
    setter({
      isRelative: !!props.isRelative,
      isTransparent: !!props.isTransparent,
      black: !!props.black,
      pathname,
    });
  }, [pathname, JSON.stringify(props)]);
  return null;
};
